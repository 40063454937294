<template>
  <div
    class="horizontal-search"
    :class="variant"
  >
    <h1
      v-if="variant === 'widget'"
      class="title s4 light"
    >
      <IconNumerique />
      {{ $t("recherche.ressources-numeriques") }}
    </h1>
    <form @submit.prevent="submitForm()">
      <p class="stop-word bold text-medium">
        {{ $t("recherche.rechercher-par") }}
      </p>
      <SelectClassic
        v-model="selectedOptions.level"
        :options="levels"
        :label="$t('recherche.le-niveau')"
        inline
        @select="handleSelect('level', $event)"
      />
      <div class="flex-vcenter">
        <p class="stop-word inside-word bold text-medium">
          {{ $t('general.dans') }}
        </p>
        <SelectClassic
          v-model="selectedOptions.subject"
          :disabled="subjects.length === 0"
          :options="subjects"
          :label="$t('recherche.la-discipline')"
          inline
          @select="handleSelect('subject', $event)"
        />
      </div>
      <div class="flex-vcenter">
        <p class="stop-word inside-word bold text-medium">
          {{ $t('general.et') }}
        </p>
        <SelectClassic
          v-model="selectedOptions.grade"
          :disabled="classes.length === 0"
          :options="classes"
          :label="$t('recherche.une-classe-de')"
          inline
          @select="handleSelect('grade', $event)"
        />
      </div>
      <div
        v-if="variant === 'widget'"
        class="flex-vcenter form-submit-container"
      >
        <ButtonClassic
          class="form-submit"
          type="button"
          color="primary"
          variant="solid"
          icon="left"
          size="small"
          :disabled="!selectedOptions.level.value"
          @click="resetFilters()"
        >
          <template #left-icon>
            <IconTrashReset />
          </template>
        </ButtonClassic>
        <ButtonClassic
          :label="$t('recherche.lancer-la-recherche')"
          class="form-submit"
          type="submit"
          icon="left"
          variant="solid"
          color="secondary"
          :disabled="isSubmitButtonDisabled"
        >
          <template #left-icon>
            <UilSearchAlt />
          </template>
        </ButtonClassic>
      </div>

      <template v-else>
        <ButtonClassic
          class="form-submit"
          type="submit"
          color="secondary"
          variant="special"
          icon="left"
          :disabled="isSubmitButtonDisabled"
          :title="$t('recherche.lancer-la-recherche')"
        >
          <template #left-icon>
            <UilSearchAlt />
          </template>
        </ButtonClassic>
        <ButtonClassic
          class="form-submit"
          color="primary"
          variant="solid"
          icon="left"
          size="small"
          :disabled="!selectedOptions.level.value"
          @click="resetFilters()"
        >
          <template #left-icon>
            <IconTrashReset />
          </template>
        </ButtonClassic>
      </template>
    </form>
  </div>
</template>

<script>
import { ButtonClassic, SelectClassic, IconTrashReset } from "@lde/core_lde_vue";
import { UilSearchAlt } from "@iconscout/vue-unicons";

import IconNumerique from "@/components/icons/IconNumerique.vue";

import Api from "@/modules/axios";

/**
 * Affiche une barre de recherche contenant des filtres prédéfinis.
 */
export default {
  name: "HorizontalSearchFormNum",
  components: {
    SelectClassic,
    ButtonClassic,
    UilSearchAlt,
    IconTrashReset,
    IconNumerique,
  },
  props: {
    /**
     * Variante du widget.
     */
    variant: {
      type: String,
      validator: (value) => ["default", "widget"].includes(value),
      default: "default",
    },
  },
  data() {
    return {
      levels: [],
      subjects: [],
      classes: [],
      selectedOptions: {
        level: {
          label: "",
          value: "",
        },
        subject: {
          label: "",
          value: "",
        },
        grade: {
          label: "",
          value: "",
        },
      },
    };
  },
  computed: {
    /**
     * Est-ce que le bouton de soumission de formulaire est désactivé ?
     * @returns {Boolean}
     */
    isSubmitButtonDisabled() {
      const level = this.selectedOptions?.level?.value;
      const subject = this.selectedOptions?.subject?.value;

      return level === "" || subject === "";
    },
  },
  mounted() {
    // On récupère tous les niveaux disponibles au chargement du composant.
    this.getLevels();
  },
  methods: {
    handleSelect(type, val) {
      this.$set(this.selectedOptions, type, val);
      switch (type) {
        case "level":
          this.getSubjects();
          break;
        case "subject":
          this.getGrades();
          break;
        default:
          break;
      }
      this.$matomo.trackEvent(
        `form_convers_${this.variant}_${type}_selected`,
        "click",
        val.label,
      );
    },
    /**
     * Soumission du formulaire.
     */
    submitForm() {
      const query = {
        only: "numeriques",
        quicksearch: true,
      };

      if (this.selectedOptions?.level?.label) {
        query.numeriques_adv_niveaux = this.selectedOptions?.level?.value;
      }
      if (this.selectedOptions?.subject?.label) {
        query.numeriques_adv_matieres = this.selectedOptions?.subject?.label;
      }
      if (this.selectedOptions?.grade?.label) {
        query.numeriques_adv_classes = this.selectedOptions?.grade?.value;
      }

      this.$router.push({
        name: "search",
        query,
      });
    },
    /**
     * Extraction des filtres reçus dans la réponse API lors d'une requête de recherche.
     * @param {Array} filters - Tableau de filtres.
     * @param {String} slug - Nom du tableau dont on veut les option.
     * @returns {Array} Filtres. Si non définis, ils seront tous retournés.
     */
    extractFiltersFromAPI(filters, slug) {
      // Si le paramètre slug existe, on ne garde que les options contenues dans le bon tableau
      const neededFilters = (typeof slug !== "undefined")
        ? filters.filter((filter) => filter.slug === slug)
        : filters;

      const [{ options = [] }] = neededFilters;

      // Renommage des clés pour coller à ce qu'attend SelectClassic
      return options.map(({ id, libelle }) => ({
        value: id,
        label: libelle,
      }));
    },
    /**
     * Récupération de tous les niveaux disponibles.
     */
    getLevels() {
      Api().get("/recherche/formulaire_conversationnel_numerique/")
        .then(({ data }) => {
          this.levels = this.extractFiltersFromAPI(data, "niveau");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Récupération de toutess les matières disponibles.
     */
    getSubjects() {
      // Remise à zéro des données
      this.subjects = [];
      this.classes = [];

      this.selectedOptions.subject.label = "";
      this.selectedOptions.subject.value = "";
      this.selectedOptions.grade.label = "";
      this.selectedOptions.grade.value = "";

      // Est-ce qu'un niveau a déjà été renseigné ?
      const level = this.selectedOptions?.level?.value;

      if (level) {
        Api().get("/recherche/formulaire_conversationnel_numerique/", {
          params: {
            niveau: level,
          },
        })
          .then(({ data }) => {
            // On extrait les filtres de la réponse API
            this.subjects = this.extractFiltersFromAPI(data, "matiere");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    /**
     * Récupération de toutess les classes disponibles.
     */
    getGrades() {
      // Remise à zéro des données
      this.classes = [];

      this.selectedOptions.grade.label = "";
      this.selectedOptions.grade.value = "";

      // Est-ce qu'un niveau ET une discipline ont déjà été renseignés ?
      const level = this.selectedOptions?.level?.value;
      const subject = this.selectedOptions?.subject?.value;

      if (level && subject) {
        Api().get("/recherche/formulaire_conversationnel_numerique/", {
          params: {
            niveau: level,
            matiere: subject,
          },
        })
          .then(({ data }) => {
            // On extrait les filtres de la réponse API
            this.classes = this.extractFiltersFromAPI(data, "classe");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    /**
     * Remet à zéro les filtres des dropdowns.
     */
    resetFilters() {
      this.subjects = [];
      this.classes = [];

      this.selectedOptions = {
        level: {
          label: "",
          value: "",
        },
        subject: {
          label: "",
          value: "",
        },
        grade: {
          label: "",
          value: "",
        },
      };
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/horizontal_search.scss";
</style>
